<template>
    <div>
        <div class="container" style="padding: 1rem">
            <div class="row">
                <div class="d-flex">
                    <div class="mx-auto d-flex">
                        <img :src="require('../assets/img/new/ghs-01.png')" alt="Logo Here" style="width: 100px">
                        <h1 class="text-secondary-orange" style="padding-top: 5pt;padding-left: 7pt;text-indent: 0pt;text-align: left;">Terms and Conditions</h1>
                    </div>
                </div>
                <h4 class="mt-2" style="padding-left: 7pt;text-indent: 0pt;text-align: left;">Introduction</h4>

                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">These Terms and Conditions (“Terms and Conditions”) form a legal agreement between Gear Health Systems together with all its affiliates and subsidiaries (“Gear Health Systems” “We”), and you, the individual (“you” and “your”) concerning your access to and use of the various services offered through the Sites and the Gear Health Systems platforms.</p>

                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">These terms and conditions apply to the Gear Health Systems website (the “Site”) and all of its subsidiaries, and affiliates operated Internet sites which reference these Terms and Conditions.</p>
                
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Use of any of the Sites or platforms constitutes your acceptance of these Terms and Conditions of use. The Site reserves the right, to change, modify, add, or remove portions of both the Terms and Conditions of use at any time. Changes will be effective when posted on the Site with no other notice provided.</p>
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Please check these Terms and Conditions regularly for updates. Your continued use of the Site following the posting of changes to these Terms and Conditions constitutes your acceptance of those changes.</p>
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Kindly review the Terms and Conditions listed below diligently prior to using this website as your use of the website indicates your agreement to be wholly bound by its Terms and Conditions without modification.</p>
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You agree that if you are unsure of the meaning of any part of these Terms and Conditions or have any questions regarding the Terms and Conditions, you will not hesitate to contact us for clarification. These Terms and Conditions fully govern the use of this website. No extrinsic evidence, whether oral or written, will be incorporated.</p>
                
                <ol id="l1">
                    <li data-list-text="2.">
                    <h4 style="padding-left: 18pt;text-indent: -11pt;text-align: left;">Your representations and warranties</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">By registering on the Platforms, you represent and warrant the following: </p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;"> &bull; You are at least eighteen (18) years of age,</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;"> &bull; You have the legal ability and authority to enter into these Terms and Conditions with Gear Health Systems,</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;"> &bull; The information you have provided to Gear Health Systems in your registration is accurate and complete,</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;"> &bull; You will comply with any and all laws applicable to your use of the Sites and platforms, you will not interfere with a third party’s use and enjoyment of the Sites and Platforms, you will not interfere with or disrupt Gear Health Systems or its vendors’ security measures, if any information you provide to Gear Health Systems becomes inaccurate, incomplete or otherwise false or misleading, you will immediately notify Gear Health Systems,</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"> &bull; You acknowledge that access to the healthcare services provided through the platforms are not   “insured   services”   under   any   provincial   health   plans, you acknowledge that any fees paid by you or by any other persons on your behalf are not provided in exchange for any undertaking by Gear Health Systems or its representatives that such healthcare services will be made available to you, and</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">&bull; You are accessing the Websites and Platforms for yourself or a child under the age of eighteen for whom you are the legal guardian.</p>
                    </li>
                    <li data-list-text="3.">
                    <h4 style="padding-left: 18pt;text-indent: -11pt;text-align: left;">Use of Site and Applications</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Both parties agree that this website and applications may only be used in accordance with these Terms and Conditions of Use. If you do not agree with the Terms and Conditions of Use or do not wish to be bound by them, you agree to refrain from using this website.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We grant you a non-transferable, revocable and non-exclusive license to use this Site, in accordance with the Terms and Conditions of use, for the following:</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;line-height: 12pt;text-align: justify;">Accessing healthcare information available on the Site and Applications</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Connecting with patients and medical practitioners in relation to receipt and provision of health products and services, telemedicine, book facilities, doctors and expert healthcare services available on the Site</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Gathering prior information regarding our products and services and purchasing or providing the services.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Gear Health Systems’ role is limited to making certain telehealth related information available to you and/or facilitate your access to telemedicine and expert medical services. Gear Health Systems is independent from healthcare providers who will be providing such telemedicine services to you through the Platform and is not responsible for such healthcare providers’ acts, omissions or for any content of the communications made by them. Gear Health Systems does not engage in the practice of medicine or provide any other health services.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Commercial use or use on behalf of any third party is prohibited, except as explicitly permitted by us in advance.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Certain services and related features that may be made available on the Site may require registration or subscription. Should you choose to register or subscribe for any such services or related features, you agree to provide accurate and current information about yourself, and to promptly update such information if there are any changes.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Every user of the Site is solely responsible for keeping passwords and other account identifiers safe and secure.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">The account owner is entirely responsible for all activities that occur under such password or account. Furthermore, you must notify us of any unauthorized use of your password or account. The Site shall not be responsible or liable, directly or indirectly, in any way for any loss or damage of any kind incurred as a result of, or in connection with, your failure to comply with this section. During the registration process you agree to receive promotional emails from the Site. You can subsequently opt out of receiving such promotional emails by clicking on the link at the bottom of any promotional email.</p>
                    
                    </li>
                    <li data-list-text="4.">
                    <h4 style="padding-left: 18pt;text-indent: -11pt;text-align: left;">Shipping and Return Policy</h4>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">At Gear Health Systems, we do everything possible to make sure that you are completely satisfied with our products. We have an authentication process that ensures that the products delivered to you are of high quality.</p>
                    
                    <p class="text-dark fw-bold mb-0" style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">For Prescription Health Products:</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Please ensure you personally receive your order to receive any counselling on medicine administration and handover the original prescription to the delivery rider as you receive your order. We remind you that this is a legal obligation for the receipt of all prescription-only medication.</p>
                    
                    <p class="text-dark fw-bold mb-0" style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">For Insurance or third party payer Orders:</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Ensure you personally receive your order to receive counselling on medicine administration and process your biometrics against your insurance card. In addition, please handover the original prescription and Claim Form to the rider. Without both the original prescription and claim form, your insurer or third party may decline to cover your medication.</p>
                    
                    <p class="text-dark fw-bold mb-0" style="padding-left: 7pt;text-indent: 0pt;text-align: left;">Return and Refund Policy:</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;line-height: 214%;text-align: left;">We will provide a full refund/credit note based on the conditions listed below: </p>
                    <p style="padding-left: 7pt;text-indent: 0pt;line-height: 214%;text-align: left;"> &bull; The purchase was paid for during the ordering process and; you received a defective item;</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;line-height: 214%;text-align: left;"> &bull; The ordered item(s) is lost or damaged during transit; the ordered item(s) is past its expiry date.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"> &bull; If you are not fully satisfied with your purchase due to any of the above, you can return the product for a full refund/credit note or exchange. Items MUST be returned in their original packaging in an unused state. Please keep the receipt as emailed to you or was contained inside your package.</p>
                    
                    <p style="padding-left: 7pt;text-indent: 2pt;text-align: justify;">We will not accept a return request resulting from a change of mind or the picking of a wrong product from our platforms. We recommend you inspect your order as soon as it is delivered to you as we are not able to accept returned products that have been opened or used unless they are defective.</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">Items for refund should be returned within 48 hours.</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Any products that you wish to return must be in the original packaging and unopened. If the product to be returned does not meet these conditions, we will be unable to offer a refund.</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">You will not be able to give you a refund/Credit Note if:</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;line-height: 214%;text-align: left;">You simply change your mind You bought an item by mistake</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">How to Request a Refund/Credit Note:</p>
                    
                    <p class="s1" style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"><a href="mailto:info@gearhealthsystem.com" class="s2" target="_blank">To request a refund, simply email us on </a><span style=" color: #0462C1; font-family:&quot;Microsoft Sans Serif&quot;, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 11pt;">info@gearhealthsystem.com</span> <span style=" color: #505050;">with your order details, including the reason you&#39;re requesting a refund. We take customer feedback very seriously and use it to constantly improve our quality of service.</span></p>
                    
                    <p style="padding-top: 5pt;padding-left: 7pt;text-indent: 0pt;text-align: justify;"><a href="mailto:info@gearhealthsystem.com" class="s2" target="_blank">If you have any queries, email us at </a><a href="mailto:info@gearhealthsystem.com" class="a" target="_blank">info@gearhealthsystem.com</a>, or contact our customer support executives through online chat. We&#39;re here for you!</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Please note: Should you be entitled to a credit note, this will be available in your account within 24 hours of the receipt and resolution of a valid complaint as provided in the refund policy during the hours of operations.</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Should a refund be authorized, we will process this within 1 (one) business day, after which the reversal into your account will be subject to your payment provider’s refund policy.</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We will process refunds through your original method of payment.</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We will only process refund requests received within 48 hours (2 days) of initial payment.</p>
                    
                    </li>
                    <li data-list-text="5.">
                    <h4 style="padding-left: 18pt;text-indent: -11pt;text-align: left;">Prohibited uses</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">In using the Site and platforms, you agree not to:</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"> &bull; Send or otherwise transmit to or through the Site and platforms any unlawful, infringing, harmful, harassing, defamatory, threatening, hateful or otherwise objectionable material of any kind, any material that can cause harm or delay to the Sites and platforms or computers of any kind, and any unsolicited email and/or advertisement or promotion of goods and services; malicious software or code; unlawful, harassing, privacy invading, abusive, threatening, vulgar, obscene, racist or potentially harmful content; any content that infringes a third party right or intellectual property; any content that may cause damage to a third party; any content which may constitute, cause or encourage a criminal action or violate any applicable law; Any illegal content</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"> &bull; Misrepresent your identity or affiliation in any way;</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"> &bull; Restrict or inhibit any person from using the Site and platforms, disclose personal information obtained from the Sites and Platforms or collect information about users of the Site and platforms;</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"> &bull; Reverse engineer, disassemble or decompile any section or technology on the Websites and Platforms, or attempt to do any of the foregoing;</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"> &bull; Gain unauthorized access to the Site and platforms, to other users’ accounts, names, personally identifiable information or other information, or to other computers or websites connected or linked to the Websites and Platforms;</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"> &bull; Launch or use any automated system, including without limitation, “robots,” “spiders,” or “offline readers,” that access the Sites and platforms in a manner that sends more request messages to our servers in a given period of time than a human can reasonably produce in the same period by using a conventional web browser;</p>
                    
                    <p style="text-indent: 0pt;text-align: left;"/>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"> &bull; Send or otherwise transmit to or through the Site and platforms chain letters, unsolicited messages, so-called “spamming” or “phishing” messages, or messages marketing or advertising goods and services;</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"> &bull; Post, transmit or otherwise make available any virus, worm, spyware or any other computer code, file or program that may or is intended to damage or hijack the operation of any hardware, software or telecommunications equipment;</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"> &bull; Violate any applicable laws or regulations in any way;</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"> &bull; Alter or modify any part of the content or services offered on or through the Site and platforms;</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"> &bull; Allow any other person to use the platforms with your registration or login information; Accessing our servers or internal computer systems, interfering in any way with the functionality of this website, gathering or altering any underlying software code, infringing any intellectual property rights;</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"> &bull; Breach or otherwise circumvent Gear Health Systems’ security or authentication measures;</p>
                    
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"> &bull; Assist or permit any persons in engaging in any of the activities described above. Any breach of these Terms and Conditions of use shall result in the immediate revocation of the license granted without prior notice to you. Should we determine at our sole discretion that you are in breach of any of these conditions, we reserve the right to deny you access to this website and its contents and do so without prejudice to any available remedies at law or otherwise.</p>
                    
                    </li>
                    <li data-list-text="6.">
                    <h4 style="padding-left: 18pt;text-indent: -11pt;text-align: left;">Information submitted by you</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Any information that you submit to the Site and/or provide to us, including but not limited to, questions, reviews, comments, and suggestions will become our sole and exclusive property and shall not be returned to you.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">In addition to the rights applicable to any information, when you post comments or reviews to the Site, you also grant us the right to use the name that you submit, in connection with such review, comment, or other content</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You shall not use a false e-mail address, pretend to be someone other than yourself or otherwise mislead us or third parties as to the origin of any Submissions. We may, but shall not be obligated to, remove or edit any Submissions.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">By signing up for health services, you agree to receive a) emails associated with processing your request, b) promotional emails, SMS and push notifications from Gear Health Systems. You may unsubscribe from promotional emails via a link provided in each email. If you would like us to remove your personal information from our database, unsubscribe from emails and/or SMS, please email Customer Service email address by country.</p>
                    
                    </li>
                    <li data-list-text="7.">
                    <h4 style="padding-left: 18pt;text-indent: -11pt;text-align: left;">Accessibility of Site</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Our aim is to ensure accessibility to the Site at all times, however we make no representation of that nature and reserves the right to terminate the Site at any time and without notice.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You accept that service interruption may occur in order to allow for Site improvements, scheduled maintenance or may also be due to external factors beyond our control.</p>
                    </li>
                    <li data-list-text="8.">
                    <h4 style="padding-left: 18pt;text-indent: -11pt;text-align: left;">Links and Thirds Party Sites</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We may include links to third party websites at any time. However, the existence of a link to another website should not be considered as an affiliation or a partnership with a third party or viewed as an endorsement of a particular website unless explicitly stated otherwise.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">In the event the user follows a link to another Site, he or she does so at his or her own risk. We accept no responsibility for any content, including, but not limited to, information, products and services, available on third party websites. Creating a link to this website is strictly forbidden without our prior written consent. Furthermore, we reserve the right to revoke our consent without notice or justification.</p>
                    
                    </li>
                    <li data-list-text="9.">
                    <h4 style="padding-left: 18pt;text-indent: -11pt;text-align: left;">No endorsements</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Reference to any product, recording, event, process, publication, service, or offering of any third party by name, trade name, trademark, service mark, company name or otherwise does not constitute or imply the endorsement or recommendation of such by Gear Health Systems. Any views expressed by third parties on the Site and platforms are solely the views of such third party and Gear Health Systems assumes no responsibility for the accuracy or veracity of any statement made by such third party.</p>
                    
                    </li>
                    <li data-list-text="10.">
                    <h4 style="padding-left: 24pt;text-indent: -17pt;text-align: left;">Intellectual Property</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Both parties agree that all intellectual property rights and database rights, whether registered or unregistered, in the Site, information content on the Site and all the website design, including, but not limited to, text, graphics, software, photos, video, music, sound, and their selection and arrangement, and all software compilations, underlying source code and software shall remain at all times vested in us or our licensors. Use of such material will only be permitted as expressly authorized by us or our licensors.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Any unauthorized use of the material and content of this website is strictly prohibited and you agree not to, or facilitate any third party to, copy, reproduce, transmit, publish, display, distribute, commercially exploit or create derivative works of such material and content.</p>
                    
                    </li>
                    <li data-list-text="11.">
                    <h4 style="padding-left: 24pt;text-indent: -17pt;text-align: left;">Data Protection</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Any personal information collected in relation to the use of this website will be held and used in accordance with our Privacy Policy, which is available on our Site.</p>
                    
                    </li>
                    <li data-list-text="12.">
                    <h4 style="padding-left: 24pt;text-indent: -17pt;text-align: left;">Indemnity</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You agree to indemnify and hold us, our affiliates, officers, directors, agents and/or employees, as the case may be, free from any claim or demand, including reasonable legal fees, related to your breach of these Terms of use.</p>
                    
                    </li>
                    <li data-list-text="13.">
                    <h4 style="padding-left: 22pt;text-indent: -15pt;text-align: justify;">Applicable Law and Jurisdiction</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">These Terms and Conditions shall be interpreted and governed by English Law. Each party hereby agrees to submit to the jurisdiction of the English courts.</p>
                    
                    </li>
                    <li data-list-text="14.">
                    <h4 style="padding-left: 24pt;text-indent: -17pt;text-align: left;">Arbitration</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">The parties shall use their best efforts to amicably settle any dispute arising out of or in connection with this Agreement (including any question regarding its interpretation existence validity or termination). If such dispute controversy or claim cannot be settled within ten (10) days after receipt by one party of the other party’s request for such amicable settlement, the parties shall refer the dispute to a mediator agreed upon between them in accordance with the LCIA Mediation Rules, which Rules are deemed to be incorporated by reference into this clause, and if within ten (10) days of one party requesting mediation the</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">parties do not agree on a mediator or fail to settle the dispute through mediation, the dispute shall be referred to and finally resolved by arbitration under the London Court of International Arbitration (LCIA) Rules, which Rules are deemed to be incorporated by reference into this clause.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">The language to be used in the mediation and in the arbitration shall be English. In any arbitration commenced pursuant to this clause:</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;line-height: 12pt;text-align: justify;">the number of arbitrators shall be three; and</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">the seat, or legal place, of arbitration shall be London.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">The award of the arbitrators shall be final and binding upon the parties and any party may apply to a court of competent jurisdiction for enforcement of such award. The award of the arbitrators may take the form of an order to pay an amount or to perform or to prohibit certain activities.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Notwithstanding the foregoing, a Party is entitled to seek preliminary injunctive relief or interim or conservatory measures from any court of competent jurisdiction pending the final decision or award of the arbitrators.</p>
                    
                    </li>
                    <li data-list-text="15.">
                    <h4 style="padding-left: 24pt;text-indent: -17pt;text-align: left;">Termination</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">In addition to any other legal or equitable remedies, we may, without prior notice to you, immediately terminate the Terms and Conditions or revoke any or all of your rights granted under the Terms and Conditions. Upon any termination of this Agreement, you shall immediately cease all access to and use of the Site and we shall, in addition to any other legal or equitable remedies, immediately revoke all password(s) and account identification issued to you and deny your access to and use of this Site in whole or in part. Any termination of this agreement shall not affect the respective rights and obligations (including without limitation, payment obligations) of the parties arising before the date of termination. You furthermore agree that the Site shall not be liable to you or to any other person as a result of any such suspension or termination. If you are dissatisfied with the Site or with any terms, conditions, rules, policies, guidelines, or practices of Gear Health Systems in operating the Site, your sole and exclusive remedy is to discontinue using the Site.</p>
                    
                    </li>
                    <li data-list-text="16.">
                    <h4 style="padding-left: 24pt;text-indent: -17pt;text-align: left;">Severability</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">If any portion of these terms or conditions is held by any court or tribunal to be invalid or unenforceable, either in whole or in part, then that part shall be severed from these Terms and Conditions of Use and shall not affect the validity or enforceability of any other section listed in this document.</p>
                    
                    </li>
                    <li data-list-text="17.">
                    <h4 style="padding-left: 24pt;text-indent: -17pt;text-align: left;">Electronic documents</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">This electronic document, and all other electronic documents referred to or incorporated herein, will be: (a) deemed for all purposes to be a “writing” or “in writing”, and to comply with all statutory, contractual, and other legal requirements for a writing; and (b) legally enforceable as a signed agreement. A printed version of these Terms and Conditions and any notice given in electronic form shall be admissible in judicial proceedings or administrative proceedings based upon or relating to these Terms and Conditions to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</p>
                    
                    </li>
                    <li data-list-text="18.">
                    <h4 style="padding-left: 24pt;text-indent: -17pt;text-align: left;">Assignment</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">These Terms and Conditions are personal to you, and are not assignable, transferable, or sub licensable by you except with Gear Health Systems’ prior written consent. Gear Health</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Systems may assign, transfer, or delegate any of its rights and obligations hereunder without your consent.</p>
                    
                    </li>
                    <li data-list-text="19.">
                    <h4 style="padding-left: 24pt;text-indent: -17pt;text-align: left;">Disclaimer</h4>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">The Site and platforms are provided on an “as is” and “as available” basis and without warranties of any kind, either express or implied. To the fullest extent permissible pursuant to applicable law, Gear Health Systems disclaims all representations, warranties, and conditions, express or implied, including, but not limited to, implied condition or warranties of merchantability and fitness for a particular purpose.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Gear Health Systems does not warrant that the Site and platforms will be uninterrupted or error-free, that defects will be corrected or that the Site and Platforms or the server that makes it available are free of viruses or other harmful components.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Healthcare service representations expressed on this Site are those of the healthcare service providers and are not made by us. Submissions or opinions expressed on this Site are those of the individual posting such content and may not reflect our opinions.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Gear Health Systems makes no guarantees, and disclaims any implied warranty or representation about its accuracy, relevance, timeliness, completeness, or appropriateness of any content posted on the Site and platforms for a particular purpose. Gear Health Systems assumes no liability arising from or relating to any error, omission, interruption, deletion, delay in operation or transmission, computer virus, communication failure and defect in the information, content, materials, software or other services, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Site and/or Platforms.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Applicable law may not allow the exclusion of implied warranties, so the above exclusion may not apply to you.</p>
                    
                    </li>
                        <li data-list-text="20.">
                        <h4 style="padding-left: 24pt;text-indent: -17pt;text-align: left;">Contacts/Notices</h4>
                        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">If you have any questions about the Terms and Conditions, or need to provide notice to, or communicate with, Gear Health Systems under the Terms and Conditions, please contact Gear Health Systems using the following contact details:</p>
                    </li>
                </ol>
            </div>
        </div>

        <main-footer v-if="footer" :page="'Home'" v-model:prop_data="footer"></main-footer>
  </div>
</template>


<script>
  import MainFooter from '../components/Footer'
  export default {
    data() {
      return {
        footer: {
          "title": "About Gear Health Systems.",
          "address": "",
          "phone_number": "+254 (0)713 152 100",
          "email": "info@gearhealthsystem.com",
          "background_color": "dark",
          "description": "Gear Health Systems is Disrupting Access Barriers to Quality Healthcare in Africa",
          "call_to_action": {
            "text": "Contact us",
            "background_color": "secondary-orange",
            "color": "white",
            "link": "#contacts"
          },
          navigation: [
            {
              "text": "Team",
              "link": "#team",
              "type": "id"
            },
            {
              "text": "The Problem",
              "link": "#problem",
              "type": "id"
            },
            {
              "text": "Services",
              "link": "#services",
              "type": "id"
            },
            {
              "text": "Partners",
              "link": "#partners",
              "type": "id"
            },
            {
              "text": "Contacts",
              "link": "#contacts",
              "type": "id"
            },
            {
              "text": "Terms of Service",
              "link": "tos",
              "type": "route"
            }   ,         
            {
              "text": "Privacy Policy",
              "link": "privacy",
              "type": "route"
            }   
          ],
          socials: [
            {
              "social": "twitter",
              "link": "https://www.twitter.com/@GearHealthSys",
              "background_color": "info"
            },
            {
              "social": "linkedin",
              "link": "https://www.linkedin.com/company/gear-health-systems",
              "background_color": "primary"
            },
            {
              "social": "instagram",
              "link": "http://www.instagram.com/gearhealthsys",
              "background_color": "burgundy"
            },
            {
              "social": "youtube",
              "link": "https://www.youtube.com/channel/UCQjUg2zZ-jx1KfQMcDkmr7w",
              "background_color": "danger"
            },
            {
              "social": "facebook",
              "link": "https://www.facebook.com/Gear-Health-Systems-102109359062443",
              "background_color": "primary"
            }                                     
          ],          
        },
      }
    },
    components: {
      MainFooter
    }, 
  }
</script>